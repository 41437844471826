<template>
  <div class="device-drinks">
    <el-row class="device-drink-info">
      <el-col :span="14">
        <p>
          <label>设备名称:</label> <span>{{device.deviceName}}</span> <span class="mini">({{device.deviceUuid}})</span>

        </p>
        <p><label>设备联系人/联系方式:</label> <span>{{device.dutyUserName}}/ {{device.dutyUserPhone}}</span></p>
        <p><label>设备位置:</label>
          <span v-if="device.province && device.province.length != 0">{{device.province}}/{{device.city}}/{{device.address}}</span>
          <span v-else>暂未设置地理位置</span>
        </p>
      </el-col>
      <el-col :span="10" class="device-operate btn">
        <el-button type="primary" size="mini" @click="close" plain>返回列表</el-button>
        <el-button class="warn" size="mini" @click="fullPullDevice">拉取设备饮品</el-button>
        <!--<el-button class="warn" size="mini" @click="showCreateDrink">自定义新增</el-button>-->
        <el-button class="error" size="mini" @click="syncDevice">设备饮品更新</el-button>
      </el-col>
    </el-row>
    <div class="device-drink-data-list">
      <el-table :data="drinks">
        <empty slot="empty"></empty>
        <el-table-column label="饮品信息" align="center" min-width="160">
          <template slot-scope="scope">
            <div class="drink-item-info">
              <img :src="scope.row.img" class="drink-item-info-img">
              <span class="drink-item-info-content">{{scope.row.name}}    /     {{scope.row.engName}}</span>
            </div>

          </template>
        </el-table-column>
        <el-table-column label="小杯价格(元)" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.price | F1000}}</span>
          </template>
        </el-table-column>
        <el-table-column label="中杯价格(元)" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.middlePrice | F1000}}</span>
          </template>
        </el-table-column>
        <el-table-column label="大杯价格(元)" align="center">
          <template slot-scope="scope">
            <span>{{scope.row.bigPrice | F1000}}</span>
          </template>
        </el-table-column>
        <el-table-column label="排序(越小越靠前)" align="center" prop="orderCond"></el-table-column>
        <el-table-column label="操作" align="center" prop="name">
          <template slot-scope="scope">
                        <span @click="handleEdit(scope.row)"
                              class="device-drink-edit">编辑</span>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      @current-change="handleCurrentChange"
      class="pagination"
      :current-page="pagination.curPage"
      :page-size="pagination.limit"
      layout="total, prev, pager, next, jumper"
      :total="pagination.total">
    </el-pagination>


    <c-dialog :visible.sync="editCoffeeVisible" class="cup-dialog" :title="'编辑饮品'" :width="'42rem'"
              :height="'28rem'">
      <el-button slot="title" size="mini" type="primary" @click="saveCoffeeEdit">保存</el-button>
      <div class="check">
        <el-row :gutter="20">
          <el-col :span="11">
            <upload :url="drink.img"></upload>
          </el-col>
          <el-col :span="12">
            <el-form label-width="120px" size="mini">
              <el-form-item label="饮品名称">
                <el-input v-model="drink.name" placeholder="请输入饮品名称"/>
              </el-form-item>
              <el-form-item label="饮品英文名称">
                <el-input v-model="drink.engName" placeholder="请输入饮品名称"/>
              </el-form-item>
              <el-form-item label="小杯价格">
                <el-input v-model="drink.price" placeholder="单位为元，支持小数点后两位"
                          @change="drink.price=materielExtraCostChange(drink.price)" :min="0"/>
              </el-form-item>

              <el-form-item label="中杯价格">
                <el-input v-model="drink.middlePrice" placeholder="单位为元，支持小数点后两位"
                          @change="drink.middlePrice=materielExtraCostChange(drink.middlePrice)" :min="0"/>
              </el-form-item>
              <el-form-item label="大杯价格">
                <el-input v-model="drink.bigPrice" placeholder="单位为元，支持小数点后两位"
                          @change="drink.bigPrice=materielExtraCostChange(drink.bigPrice)" :min="0"/>
              </el-form-item>
              <el-form-item label="排序位置">
                <el-input size="mini" v-model.number="drink.orderCond" placeholder="越小越靠前"></el-input>
              </el-form-item>


            </el-form>
          </el-col>
        </el-row>
      </div>
    </c-dialog>
    <loading :show="showLoading"/>
  </div>

</template>

<script>
  import {
    drinkDeviceUnion, drinkDeviceList, drinkDeviceUpdate,
    drinkSyncDevice, drinkFullPull
  } from '@/api/equipment/drink'
  import {fillObj} from "@/util/checkLogin";
  import CDialog from '@/components/Dialog'
  import Upload from '@/components/Upload'
  import Loading from '@/components/Loading'

  export default {
    name: '',
    components: {
      CDialog,
      Upload,
      Loading
    },
    data: function () {
      return {
        device: {
          deviceUuid: '',
          deviceName: '',
          wechatPay: 0,
          aliPay: 0,
          groupUuid: '',
          province: '',
          city: '',
          address: '',
          lat: '',
          lng: '',
          dutyUserName: '',
          dutyUserPhone: '',
        },
        drinkStoreVisible: false,
        selfConfVisible: false,
        editCoffeeVisible: false,
        showLoading: false,
        drinkSort: {
          uuids: [],
          price: 0,
          deviceUuid: '',
        },
        drinks: [],
        drinkTmpls: [],
        pagination: {
          deviceUuid: '',
          start: 0,
          curPage: 1,
          limit: 5,
          total: 0,
        },
        drink: {
          drinkUuid: '',
          deviceUuid: '',
          name: '',
          img: '',
          price: 0,
          middlePrice: 0,
          bigPrice: 0,
          engName: '',
          orderCond: 0,
        }

      }
    },

    methods: {
      close() {
        this.$emit('close')
      },
      resetDevice(device) {
        fillObj(this.device, device)
        this.pagination.deviceUuid = this.device.deviceUuid
        this.loadDrinks()
      },

      materielExtraCostChange(item) {
        // 防止删除为空
        if (!item) {
          item = '0.00'
        }
        // 一些错误金额输入的判断
        if (item.toString().indexOf('.') > 0 && Number(item.toString().split('.')[1].length) < 1) {
          item = item.toString().split('.')[0]
        }
        // 一些错误金额输入的判断
        if (!item || item === '-' || item === '-0') {
          item = '0.00'
        }
        return parseFloat(item).toFixed(2)

      },

      handleCurrentChange(value) {
        this.pagination.curPage = value
        this.pagination.start = (value - 1) * this.pagination.limit
        drinkDeviceList(this.pagination).then(res => {
          this.drinks = this.transPrice(res.data)
        })
      },
      transPrice(data) {
        for (let i = 0; i < data.length; i++) {
          data[i].price = data[i].price / 100
          data[i].middlePrice = data[i].middlePrice / 100
          data[i].bigPrice = data[i].bigPrice / 100
        }
        return data
      },
      loadDrinks() {
        this.pagination.curPage = 1
        this.pagination.start = 0
        drinkDeviceUnion(this.pagination).then(res => {
          this.drinks = this.transPrice(res.data.list || [])

          this.pagination.total = parseInt(res.data.total)
        })
      },

      handleEdit(item) {
        fillObj(this.drink, item)
        this.drink.deviceUuid = this.pagination.deviceUuid
        this.editCoffeeVisible = true
      },

      saveCoffeeEdit() {

        if (this.drink.name.length === 0) {
          this.$message.warning('请输入饮品名称')
          return
        }
        if (this.drink.engName.length === 0) {
          this.$message.warning('请输入饮品英文名称')
          return
        }

        this.drink.price = parseFloat(this.drink.price) * 100
        this.drink.middlePrice = parseFloat(this.drink.middlePrice) * 100
        this.drink.bigPrice = parseFloat(this.drink.bigPrice) * 100


        drinkDeviceUpdate(this.drink).then(() => {
          this.editCoffeeVisible = false
          this.handleCurrentChange(this.pagination.curPage)
          this.$message.success('编辑成功')
        })
      },


      syncDevice() {
        this.$confirm('此操作将同步饮品至设备, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let vue = this
          drinkSyncDevice({uuid: vue.device.deviceUuid}).then(res => {
            vue.showLoading = true
            setTimeout(function () {
              vue.showLoading = false

              vue.$message.success('同步成功')
            }, 10000)
          })


        }).catch(() => {

        })
      },

      fullPullDevice() {
        this.$confirm('此操作将全量拉取设备饮品至云端, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let vue = this
          drinkFullPull({uuid: vue.device.deviceUuid}).then(res => {
            vue.showLoading = true
            setTimeout(function () {
              vue.showLoading = false
              vue.loadDrinks()
              vue.$message.success('拉取成功')
            }, 5000)
          })


        }).catch(() => {

        })
      }
    },

  }
</script>

<style lang="scss">
  .device-drinks {
    padding: $padding;
    width: 100%;
    display: flex;
    flex-direction: column;

    .device-drink-info {
      padding: $padding;
      border-radius: $border-radius;
      border: 1px dashed;
      @include set_border_color($--border-color-dark, $--border-color);

      .device-info {
        p {
          font-size: .8rem;
          margin-bottom: $padding-8;

          label {
            margin-right: $padding;
          }

          span {

          }

          .mini {
            display: inline-block;

            font-size: .6rem;
          }
        }
      }

      .device-operate {
        text-align: center;
      }

      .btn {

        .el-button {
          width: 8rem;
        }

        .warn {
          color: $btn_color;
          border-color: $btn_color;
          //color: $f_th_color;
        }

        .warn:hover {
          color: $--font-04-color;
          background-color: $btn_color;
        }

        .error {
          // color: $f_th_color;
          color: $btn_sec_color;
          border-color: $btn_sec_color;
          //background-color: $btn_sec_color;
        }

        .error:hover {
          color: $--font-04-color;
          background-color: $btn_sec_color;
        }
      }

    }

    .device-drink-data-list {
      margin-top: $padding;
      flex: 1;

      .drink-item-info {
        * {
          vertical-align: middle;
        }

        &-content {
          display: inline-block;
          width: 180px;
          text-align: left;
        }

        img {
          max-width: 3rem;
          width: 3rem;
          margin: 0 $margin;
        }
      }
    }

    .device-drink-delete {
      color: $btn_sec_color;
    }

    .device-drink-edit {
      color: $btn_color;
      display: inline-block;
      margin-right: $padding;
    }

    .content-wrapper {
      height: 90%;
    }

    .cup-dialog {

    }

    .check {
      height: 100%;

      .el-row {
        height: 100%;

        .el-col {
          height: 100%;

          .cup {
            margin-top: 2%;
            padding-top: 8%;
            padding-bottom: 5%;
          }

          .el-input-number__decrease, .el-input-number__increase {
            border-radius: 50%;
            border: none;
            width: 1rem;
          }

          .el-input-number__decrease {
            background-color: transparent;
            border: 1px solid;
          }

          .el-input-number__increase {
            //background-color: $f_color;
          }

          .el-input-number--mini {
            line-height: 1rem;
          }

          .coffee-config {
            margin-bottom: 10px;
          }

        }
      }
    }

    .drink-check {
      height: 90%;
    }

    .pagination {
      margin: $padding;
    }
  }

</style>
